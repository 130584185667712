import { render, staticRenderFns } from "./tagItem.vue?vue&type=template&id=64b0546b&scoped=true&"
import script from "./tagItem.vue?vue&type=script&lang=js&"
export * from "./tagItem.vue?vue&type=script&lang=js&"
import style0 from "./tagItem.vue?vue&type=style&index=0&id=64b0546b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b0546b",
  null
  
)

export default component.exports