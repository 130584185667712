<template>
  <div class="tag">
    <div @click="itemClick">
      <el-badge :value="total" :hidden="total == 0 ? true : false" class="item">
        <div class="item-text F14" :class="isActive ? 'orange FBold' : ''">
          <slot name="tagname">全部订单</slot>
        </div>
      </el-badge>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      default: 0
    },
  },
  computed: {
    isActive() {
      if (this.$route.name == this.link) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    itemClick() {
      this.$router.push({
        name: this.link,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  display: inline-block;
  margin-right: 40px;
  .item {
    margin-top: 10px;
    .item-text {
      cursor: pointer;
      display: inline-block;
    }
  }
  .active {
    color: pink;
  }
}
</style>