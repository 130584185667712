<!--
 * @Author: Jerry
 * @Date: 2021-01-27 09:33:10
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-24 17:48:42
 * @Description: file content
-->
<template>
  <div>
    <pwd-contair>
      <div slot="menu">我的订单</div>
      <div slot="pwdContair">
        <tag-components>
          <div slot="tag">
            <tag-item link="AllOrders">
              <div slot="tagname">全部订单</div>
            </tag-item>
            <tag-item link="OrderDetermine">
              <div slot="tagname">待确认</div>
            </tag-item>
            <!-- <tag-item link="ToSignUp">
              <div slot="tagname">待签约</div>
            </tag-item> -->
            <tag-item link="WaitPayment">
              <div slot="tagname">待付款</div>
            </tag-item>
            <tag-item link="WaitSendGoods">
              <div slot="tagname">待发货</div>
            </tag-item>
            <tag-item link="WaitReceived">
              <div slot="tagname">待收货</div>
            </tag-item>
            <tag-item link="HaveSettled">
              <div slot="tagname">已结单</div>
            </tag-item>
            <tag-item link="TransactionCancel">
              <div slot="tagname">交易取消</div>
            </tag-item>
            <!-- <tag-item link="RefundManagement">
              <div slot="tagname">退款管理</div>
            </tag-item> -->
            <!-- <tag-item link="WaitInvoice" v-if="userInfo.roleType == '2'">
              <div slot="tagname">开票管理</div>
            </tag-item> -->
          </div>
          <!-- <div slot="search">
            <div class="ssssSborder">
              <div class="ssssSearch">
                <el-input
                  class="searchinput"
                  placeholder="商品名称、商品编号、订单号"
                  v-model="searchKey"
                ></el-input>
              </div>
              <div class="searchicon bg_f4f4">
                <i class="el-icon-search co_000 curPointer" @click="search"></i>
              </div>
            </div>
          </div> -->
        </tag-components>
        <router-view></router-view>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import TagComponents from "@/components/tag/tagComponents.vue";
import TagItem from "@/components/tag/tagItem.vue";
import { mapGetters } from "vuex";
export default {
  components: { pwdContair, TagComponents, TagItem },
  data() {
    return {
      searchKey: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    search() {
      this.$emit("search", { searchKey: this.searchKey });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>