<template>
  <div class="con Pbm20">
    <div class="tagleft">
      <slot name="tag"></slot>
    </div>
    <div class="tagright">
      <slot name="search"> </slot>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.con {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>